import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import settings from '../../settings';

const favicon = `${settings.IMAGES_BASE_URL}/v2/images/favicon/bmw-favicon.png`;

function SEO({ lang, meta, title, description, keywords }) {
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: description || `Learn total car control behind the wheel of a BMW at the BMW Performance Driving School.
          Multiple class offerings and challenges await in one of our three locations.`
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: description || `At the BMW Performance Driving School, there's a BMW driving course for everyone. BMW M driving
          School, BMW Driver's School and Teen Driving School.`
        },
        {
          name: `keywords`,
          content: keywords || `BMW driving school, bmw performance driving school, bmw teen driving school, bmw m driving school`
        }
      ].concat(meta)}
    >
      <link rel="icon" href={favicon} />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  title: ``,
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default SEO;

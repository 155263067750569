import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import settings from '../../settings';


const logo = `${settings.IMAGES_BASE_URL}/v2/images/bmw.svg`;
const BMWPerformanceLogo = `${settings.IMAGES_BASE_URL}/v2/images/BMW-pdc-logo.svg`;

class WesternNav extends React.Component {
  state = {
    menuIsOpen: true,
    menuToggle: false,
    isTop: true
  };

  handleMenuToggle = () => {
    this.setState((prev) => {
      return {
        menuIsOpen: !prev.menuIsOpen,
        menuToggle: !prev.menuToggle
      };
    });
  };
  render() {
    return (
      <header>
        <div className="mobile__nav">
          <div className="container">
            <Link to="/" className="mobile-logo">
              <img src={logo} alt="Logo" />
            </Link>
            <div
              role="button"
              className={`hamburger ${this.state.menuIsOpen ? ' ' : 'is-open'}`}
              onClick={this.handleMenuToggle}
              onKeyDown={this.handleMenuToggle}
              tabIndex="0"
            >
              <div className="hamburger__toggle" />
            </div>
          </div>
        </div>
        <nav className={`nav ${this.state.menuToggle ? ' ' : 'hide-nav'}`}>
          <div className="logo-wrapper">
            <Link to="/" aria-label='logo'>
              <div className="logo">
                <img src={logo} alt="Logo" />
              </div>
            </Link>
          </div>
          <ul className="navbar">
            <li>
              <a className="nav-btn" href="https://pds.eventsbmw.com/" target="_blank" rel="noopener noreferrer">
                <button>Book Now</button>
              </a>
            </li>
            <li className="bmw-logo">
              <LazyLoadImage effect="blur" src={BMWPerformanceLogo} alt="BMW Performance Academy Logo" className="bmw-logo" />
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}
export default WesternNav;
